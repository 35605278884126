.tab-nav {
	display: flex;
	margin: 0;
	padding: 0;
	list-style: none;
	width: 100%;
	justify-content: space-between;
	.nav-item {
		padding: 10px 15px;
		flex: 1;
		text-align: center;
		cursor: pointer;
		&:hover {
			background-color: var(--color-border-1);
		}
		&.selected {
			background-color: var(--color-border-2);
		}
	}
}
