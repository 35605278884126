:root {
	--color-main-bg: #ffffff;
	// --color-primary: #f44336;
	// --color-primary-hover: #E53935;
	--color-primary: #3f51b5;
	--color-primary-hover: #3949ab;
	--color-primary-3: rgba(244, 67, 54, 0.3);
	--color-white-3: rgba(0, 0, 0, 0.3);
	--color-white-1: rgba(0, 0, 0, 0.1);
	--color-white: #fff;
	--color-border-1: #f1f5f9;
	--color-border-2: #e2e8f0;
	--color-border-3: #cbd5e1;
	--color-text: #000000;
	--color-text-invert: #ffffff;

	--color-status-rejected: #ef4444;
	--color-status-scheduled: #8b5cf6;
	--color-status-postponed: #3b82f6;
	--color-status-completed: #06b6d4;
	--color-status-in-progress: #f59e0b;
	--color-status-to-next-round: #84cc16;
	--color-status-selected: #10b981;
	--color-status-panel-was-unavailable: #78716c;
	--color-status-not-scheduled: #64748b;

	--color-bg-modal: rgba(32, 33, 36, 0.6);
	--color-bg-modal-2: #ffe0b2;

	--shadow-1: 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
		0px 3px 1px -2px rgba(0, 0, 0, 0.12), 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
	--shadow-2: 0px 10px 10px rgba(0, 0, 0, 0.1);
}

::-moz-selection {
	/* Code for Firefox */
	color: var(--color-white);
	background: var(--color-primary);
}
::selection {
	color: var(--color-white);
	background: var(--color-primary);
}

* {
	box-sizing: border-box;
}

html,
body {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

body {
	margin: 0;
	font-family: "Poppins", sans-serif;
	font-weight: 300;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	letter-spacing: 0.03125rem;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

#root {
	width: 100%;
	height: 100%;
	overflow: hidden;
}
.modal-wrap {
	position: fixed;
	top: 0;
	left: 0;
	pointer-events: none;
	z-index: 400;
	* {
		pointer-events: visible;
	}
}

h1,
.h1 {
	font-size: 3.125rem;
	font-weight: 300;
	line-height: 3.125rem;
	margin: 1rem 0 1.5rem 0;
	letter-spacing: 0.0625rem;
}
h2,
.h2 {
	font-size: 2.652rem;
	font-weight: 300;
	line-height: 2.652rem;
	margin: 1rem 0 1.5rem 0;
	letter-spacing: 0.0625rem;
}
h3,
.h3 {
	font-size: 2.25rem;
	font-weight: 300;
	line-height: 2.25rem;
	margin: 1rem 0 1.5rem 0;
	letter-spacing: 0.0625rem;
}
h4,
.h4 {
	font-size: 1.75rem;
	font-weight: 300;
	line-height: 1.75rem;
	margin: 0.5rem 0;
	letter-spacing: 0.0625rem;
}
h5,
.h5 {
	font-size: 1.25rem;
	font-weight: 300;
	line-height: 1.25rem;
	margin: 0.4rem 0 0.5rem 0;
}
h6,
.h6 {
	font-size: 1rem;
	font-weight: 500;
	line-height: 1rem;
	margin: 0.3rem 0 0.5rem 0;
}

.d {
	&-flex {
		display: flex;
		&.align {
			&-items-center {
				align-items: center;
			}
		}
		.flex-fill {
			flex: 1;
		}
	}
}
.m {
	&-0 {
		margin: 0;
	}
	&l {
		&-1 {
			margin-left: 5px;
		}
		&-2 {
			margin-left: 10px;
		}
		&-3 {
			margin-left: 15px;
		}
		&-4 {
			margin-left: 20px;
		}
		&-5 {
			margin-left: 25px;
		}
	}
	&r {
		&-1 {
			margin-right: 5px;
		}
		&-2 {
			margin-right: 10px;
		}
		&-3 {
			margin-right: 15px;
		}
		&-4 {
			margin-right: 20px;
		}
		&-5 {
			margin-right: 25px;
		}
	}
	&t {
		&-1 {
			margin-top: 5px;
		}
		&-2 {
			margin-top: 10px;
		}
		&-3 {
			margin-top: 15px;
		}
		&-4 {
			margin-top: 20px;
		}
		&-5 {
			margin-top: 25px;
		}
	}
	&b {
		&-1 {
			margin-bottom: 5px;
		}
		&-2 {
			margin-bottom: 10px;
		}
		&-3 {
			margin-bottom: 15px;
		}
		&-4 {
			margin-bottom: 20px;
		}
		&-5 {
			margin-bottom: 25px;
		}
	}
}

.form-group {
	display: block;
	margin-bottom: 15px;
	.form-label {
		font-size: 10px;
	}
	.form-control {
		display: block;
		width: 100%;
		padding: 0;
		border: none;
		border-bottom: 2px solid var(--color-border-2);
		border-radius: 0;
		background-color: transparent;
		height: 40px;
		outline: none;
		&:focus {
			outline: none;
			border-bottom: 2px solid var(--color-primary);
		}
	}
	textarea.form-control {
		height: 150px;
		margin-top: 10px;
	}
}

.btn {
	border-radius: 3px;
	border: none;
	background-color: transparent;
	padding: 10px 15px;
	cursor: pointer;
	&-primary {
		color: var(--color-text-invert);
		background-color: var(--color-primary);
	}
}
