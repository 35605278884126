.calendar-dates {
	flex: 1;
	display: flex;
	flex-wrap: wrap;
	.calendar-date {
		display: flex;
		flex-direction: column;
		width: calc(100% / 7);
		border-right: 1px solid var(--calendar-color-border);
		text-align: center;
		position: relative;
		overflow: hidden;
		cursor: pointer;
		border-bottom: 1px solid var(--calendar-color-border);
		&:not(.empty) {
			&.current-date {
				background-color: var(--color-border-1);
			}
		}
		&:not(.empty).selected-date {
			background-color: var(--color-border-2);
			border-right: 1px solid var(--calendar-border-2);
		}
		.calendar-date-lebel {
			position: relative;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			font-size: 11px;
			padding: 2px 0;
			display: flex;
			flex-direction: column;
			transition: all 0.1s ease-in-out;
		}
		.calendar-date-content {
			padding: 5px;
			flex: 1;
		}
	}
}

// Large devices
@media (min-width: 992px) {
	.calendar-dates {
		.calendar-date {
			width: calc(100% / 7);
			border-right: 1px solid var(--calendar-color-border);
			&:empty {
				display: block;
			}
			&:not(.empty) {
				&:hover {
					overflow: visible;
					z-index: 200;
				}
			}
			&:not(.empty):not(.current-date):hover .calendar-date-content {
				background-color: var(--color-white);
				box-shadow: 0 1px 0 var(--color-border-2);
			}
			&.selected-date:not(.empty):hover .calendar-date-content {
				background-color: var(--color-border-2);
			}
			.calendar-date-label {
				align-self: center;
			}
		}
	}
}
