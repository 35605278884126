.calendar-days {
	display: flex;
	border-bottom: 1px solid var(--color-border-2);
	.calendar-day {
		flex: 1;
		border-right: 1px solid var(--calendar-color-border);
		text-align: center;
		height: 30px;
		line-height: 30px;
	}
}
