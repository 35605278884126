.slide-show-wrap {
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	opacity: 0;
	pointer-events: none;
	transition: all 0.2s ease-in-out;
	z-index: 5;
	&.show {
		opacity: 1;
		pointer-events: visible;
	}
	.slide-show-close {
		font-size: 30px;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		display: block;
		text-align: center;
		line-height: 42px;
		transform: rotate(45deg);
		position: absolute;
		right: 20px;
		top: 15px;
		cursor: pointer;
		transition: all 0.2s ease-in-out;
		z-index: 5;
		&:hover {
			background-color: var(--color-primary);
		}
	}
	.slide-show-prev,
	.slide-show-next {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		display: block;
		text-align: center;
		line-height: 42px;
		position: absolute;
		top: calc(50% - 20px);
		cursor: pointer;
		transition: all 0.2s ease-in-out;
		z-index: 5;
		&:hover {
			background-color: var(--color-primary);
		}
		&:after {
			width: 10px;
			height: 10px;
			border-top: 2px solid var(--color-text);
			border-right: 2px solid var(--color-text);
			content: "";
			display: block;
			position: absolute;
			top: 15px;
			left: 13px;
		}
		&.disabled {
			opacity: 0.3;
			pointer-events: none;
		}
	}
	.slide-show-prev {
		left: 20px;
		transform: rotate(-135deg);
	}
	.slide-show-next {
		right: 20px;
		transform: rotate(45deg);
	}
	.slide-show-circles {
		position: fixed;
		bottom: 20px;
		left: 0;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		pointer-events: none;
		.slide-show-circle {
			display: block;
			margin: 0 6px;
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background-color: var(--color-text);
			opacity: 0.2;
			&.active {
				opacity: 1;
			}
		}
	}
	.slide-show-container {
		text-align: center;
		.slide-show-item {
			width: 100%;
			max-width: 100%;
			height: 100%;
			max-height: 100%;
			position: fixed;
			left: -100%;
			top: 0;
			padding: 20px;
			text-align: center;
			transition: all 0.4s ease-in-out;
			.slide-show-title {
				display: none;
			}
			.image-container {
				width: 30%;
				height: 100%;
				display: flex;
				align-items: center;
				padding: 0;
				transition: all 0.4s ease-in-out;
				img {
					max-width: 100%;
					max-height: 100%;
					display: block;
					margin: 0 auto;
					position: static;
				}
			}
			&.active {
				left: 0;
				& ~ .slide-show-item {
					left: 100%;
				}
				.image-container {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
}
