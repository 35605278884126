.dropdown-wrap {
	position: relative;
	.dropdown-container {
		position: absolute;
		top: 100%;
		left: 0;
		background-color: var(--color-white);
		padding: 7px 0;
		box-shadow: var(--shadow-1);
		min-width: 100%;
		.dropdown-item {
			padding: 5px 15px;
			cursor: pointer;
			font-size: 11px;
			&:hover {
				background-color: var(--color-border-1);
			}
			&.active {
				background-color: var(--color-border-2);
				.ico {
					opacity: 1;
				}
			}
			.ico {
				margin-right: 5px;
				opacity: 0.2;
				color: var(--color-primary);
			}
		}
	}
}
