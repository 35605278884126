.vijee-main-wrap {
	background-color: var(--color-main-bg);
	color: var(--color-text);
	width: 100%;
	height: 100%;
	overflow: auto;
	display: block;
	.vijee-page {
		margin: 0 auto;
		padding: 0 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		transition: all 0.3s ease-in-out;
		&.vijee-page-works {
			padding: 40px 20px;
		}
	}
	.slide-show-wrap.show ~ .vijee-page {
		filter: blur(6px);
	}
}

.loader-container.router-loader {
	position: absolute;
	left: 50%;
	top: 50%;
}

// Small devices
@media (min-width: 576px) {
	.vijee-main-wrap {
		.vijee-page {
			max-width: 450px;
		}
		.slide-show-wrap {
			.slide-show-prev,
			.slide-show-next {
				display: none;
			}
		}
		.works-grid .works-grid-item {
			width: 47%;
		}
	}
}

// Medium devices
@media (min-width: 768px) {
	.vijee-main-wrap {
		.vijee-page {
			max-width: 750px;
		}
		.works-grid .works-grid-item {
			width: 31%;
		}
	}
}

// Large devices
@media (min-width: 992px) {
	.vijee-main-wrap {
		.vijee-page {
			max-width: 950px;
			&.vijee-page-works {
				padding: 40px 20px;
			}
		}
		.works-grid .works-grid-item {
			width: 23%;
		}
	}
}

// Extra Large devices
@media (min-width: 1200px) {
	.vijee-main-wrap .vijee-page {
		max-width: 1150px;
	}
}

// Extra Extra Large devices
@media (min-width: 1400px) {
	.vijee-main-wrap .vijee-page {
		max-width: 1350px;
	}
}

.go-backto-home {
	width: 40px;
	height: 40px;
	display: block;
	margin: 20px 0;
	border-radius: 50%;
	background-color: var(--color-primary);
	color: var(--color-white);
	cursor: pointer;
	position: relative;
	font-size: 20px;
	line-height: 40px;
	text-align: center;
}

.form-group .select-wrap {
	position: relative;
	.select-icon {
		position: absolute;
		right: 5px;
		top: 12px;
		pointer-events: none;
		color: var(--color-primary);
	}
}
