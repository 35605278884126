.code-highlighter {
	padding: 1rem !important;
	background-color: rgba(0, 0, 0, 0.05) !important;
	font-size: 14px;
	line-height: 1.5rem;
	* {
		font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
			monospace;
		margin: 0;
	}
}
