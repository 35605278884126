.calendar-wrap {
	font-size: 12px;
	background-color: var(--color-white);
	&:not(.inline) {
		position: absolute;
		width: 300px;
		height: 280px;
		z-index: 2;
	}
	&.dropdown {
		box-shadow: var(--shadow-2);
	}
	&.inline {
		.calendar-dates {
			&.month-rows-4 .calendar-date {
				height: 25%;
				max-height: 25%;
			}
			&.month-rows-5 .calendar-date {
				height: 20%;
				max-height: 20%;
			}
			&.month-rows-6 .calendar-date {
				height: calc(100% / 6);
				max-height: calc(100% / 6);
			}
			.calendar-date {
				.calendar-date-lebel {
					height: unset;
				}
			}
		}
	}
	.calendar-container {
		display: flex;
		flex-direction: column;
		height: 100%;
		.calendar-body {
			border-left: 1px solid var(--calendar-color-border);
			flex: 1;
			display: flex;
			flex-direction: column;
		}
	}
	.selected-date-value {
		text-align: center;
		padding: 5px;
		border: 1px solid var(--calendar-color-border);
		border-top: none;
	}
}
