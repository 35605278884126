.calendar-years {
	display: flex;
	flex-wrap: wrap;
	height: 100%;
	.calendar-year,
	.calendar-year-nav-prev,
	.calendar-year-nav-next {
		width: 20%;
		border-right: 1px solid var(--calendar-color-border);
		border-bottom: 1px solid var(--calendar-color-border);
		text-align: center;
		height: calc(100% / 5);
		line-height: 37px;
	}
	.calendar-year-nav-prev,
	.calendar-year-nav-next {
		font-size: 20px;
	}
}
