.calendar-head {
	position: sticky;
	top: 0;
	z-index: 400;
	background-color: var(--color-white);
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 0 5px;
	border: 1px solid var(--calendar-color-border);
	min-height: 50px;
	button {
		border: none;
		background-color: transparent;
		cursor: pointer;
		position: relative;
		line-height: 35px;
		&.calendar-head-nav-prev,
		&.calendar-head-nav-next {
			width: 32px;
			height: 32px;
			border-radius: 50%;
			margin-right: 5px;
			font-size: 24px;
			padding: 0;
			&:hover {
				background-color: var(--calendar-color-border);
			}
		}
		&.calendar-head-month,
		&.calendar-head-year {
			padding: 0 5px;
			font-size: 16px;
		}
		&.calendar-head-cancel,
		&.calendar-head-ok {
			font-size: 20px;
			width: 32px;
			height: 32px;
			margin-left: 5px;
			justify-self: flex-end;
		}
	}
	.calendar-head-nav-spacer {
		flex: 1;
	}
}

// Large devices
@media (min-width: 992px) {
	.calendar-navs {
		position: static;
		z-index: unset;
	}
}
