.calendar-months {
	display: flex;
	flex-wrap: wrap;
	height: 100%;
	.calendar-month {
		width: 25%;
		border-right: 1px solid var(--calendar-color-border);
		border-bottom: 1px solid var(--calendar-color-border);
		text-align: center;
		height: calc(100% / 3);
		line-height: 59px;
	}
}
