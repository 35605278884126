.image-container {
	position: relative;
	display: inline-block;
	padding: 50%;
	.image-loader {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 1;
		top: 0;
		left: 0;
		background-color: rgba($color: #000000, $alpha: 0.4);
		transition: all 0.2s linear;
		&:before {
			content: "";
			display: block;
			position: absolute;
			left: calc(50% - 10px);
			top: calc(50% - 10px);
			width: 20px;
			height: 20px;
			border-radius: 50%;
			border: 3px solid transparent;
			border-left-color: var(--color-primary);
			animation: image-loading 1s infinite ease-in-out;
		}
	}
	img {
		filter: blur(10px);
		transition: all 0.2s linear;
		position: absolute;
		left: 0;
		top: 0;
	}
	&.loaded {
		.image-loader {
			opacity: 0;
		}
		img {
			filter: blur(0px);
		}
	}
}

@keyframes image-loading {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
