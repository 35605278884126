.loader-container {
	display: block;
	position: relative;
	.loader {
		display: block;
		position: absolute;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		border: 2px solid #333;
		border-bottom-color: transparent;
		left: calc(50% - 10px);
		top: calc(50% - 10px);
		animation: rotation 1s linear infinite;
	}
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
