.vijee-main-wrap .vijee-page-works {
	justify-content: flex-start;
	padding: 100px 0;
	border-top: 0.01rem solid #222;
}
.works-grid {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	.works-grid-item {
		width: 100%;
		display: block;
		position: relative;
		margin-bottom: 5%;
		.image-container {
			display: none;
			position: absolute;
			top: 0;
			pointer-events: none;
			transition: all 0.2s linear;
			border: 1px solid transparent;
			cursor: pointer;
			transform-origin: 50% 100%;
			border: 1px solid var(--color-primary-3);
			&:hover {
				border: 1px solid var(--color-primary);
			}
			&:nth-child(1) {
				display: block;
				z-index: 4;
				position: relative;
				pointer-events: visible;
			}
			&:nth-child(2) {
				display: block;
				z-index: 3;
				transform: translateY(10px) scale(0.94);
				opacity: 0.5;
			}
			&:nth-child(3) {
				display: block;
				z-index: 2;
				transform: translateY(20px) scale(0.87);
				opacity: 0.3;
			}
			&:nth-child(4) {
				display: block;
				z-index: 1;
				transform: translateY(30px) scale(0.8);
				opacity: 0.15;
			}
			img {
				width: 100%;
				display: block;
			}
		}
	}
	.work-details-link {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background-color: var(--color-white-3);
		position: absolute;
		bottom: 10px;
		right: 10px;
		z-index: 100;
		cursor: pointer;
		opacity: 0.5;
		transition: all 0.2s ease-in-out;
		color: var(--color-text);
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		&:hover {
			opacity: 1;
		}
		&:after {
			content: "";
			display: block;
			width: 8px;
			height: 8px;
			border-top: 2px solid var(--color-text);
			border-right: 2px solid var(--color-text);
			transform: rotate(45deg);
			position: relative;
			left: -2px;
		}
	}
}
