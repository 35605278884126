.event-notification-wrap {
	position: fixed;
	z-index: 500;
	top: 30px;
	right: 30px;
	background-color: var(--color-bg-modal-2);
	padding: 20px;
	border-radius: 10px;
	width: 270px;
	.event-notification-close {
		position: absolute;
		border: none;
		width: 32px;
		height: 32px;
		border-radius: 50%;
		cursor: pointer;
		margin-right: 5px;
		background-color: var(--color-primary);
		color: var(--color-white);
		font-size: 14px;
		padding: 0;
		line-height: 35px;
		right: -15px;
		top: -15px;
		text-align: center;
	}
	.event-notification-container {
	}
}
