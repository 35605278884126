.custom-checkbox {
	margin-top: 6px;
	display: inline-block;
	input[type="checkbox"] {
		display: none;
	}
	.custom-checkbox-label {
		position: relative;
		padding-left: 30px;
		padding-right: 10px;
		cursor: pointer;
		min-height: 24px;
		display: block;
		&:before {
			width: 24px;
			height: 24px;
			border-radius: 3px;
			border: 2px solid #ddd;
			content: "";
			position: absolute;
			left: 0px;
			top: 0px;
			display: block;
			box-sizing: border-box;
		}
		&:after {
			width: 8px;
			height: 16px;
			border-right: 3px solid #ddd;
			border-bottom: 3px solid #ddd;
			content: "";
			position: absolute;
			left: 8px;
			top: 2px;
			display: block;
			box-sizing: border-box;
			transform: scale(1, 1) rotate(90deg);
			opacity: 0;
			transition: all 0.2s ease-in-out;
		}
	}
	input[type="checkbox"]:checked ~ .custom-checkbox-label:before {
		animation: custom-checkbox-shadow 0.35s linear;
	}
	input[type="checkbox"]:checked ~ .custom-checkbox-label:after {
		transform: scale(1, 1) rotate(45deg);
		opacity: 1;
	}
}

@keyframes custom-checkbox-shadow {
	from {
		box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.5);
	}
	to {
		box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
	}
}
